import { debounce } from "@syncfusion/ej2-base";
import { Query } from "@syncfusion/ej2-data";
import { PresetsModel } from "@syncfusion/ej2-react-calendars";
import {
    CommandModel,
    FilterSettingsModel,
    GroupSettingsModel,
    Inject,
    PageSettingsModel,
    SelectionSettingsModel,
    SortSettingsModel
} from "@syncfusion/ej2-react-grids";
import {
    ListViewComponent,
    SelectEventArgs,
    Virtualization
} from "@syncfusion/ej2-react-lists";
import { MenuEventArgs } from "@syncfusion/ej2-react-navigations";
import {
    createSpinner,
    hideSpinner,
    showSpinner,
    TooltipComponent
} from "@syncfusion/ej2-react-popups";
import React, { ReactNode } from "react";
import noRecordIcon from "../../assets/NoRecordIcon.svg";
import { RolePermissionsContext } from "../../RAFAuthentication/RAFRolePermissionsContextProvider";
import {
    GridColumnHeader,
    GridColumnTemplates,
    GridColumnWidth
} from "../Grid/RAFSFGridUtils";
import { Constants } from "../../constants/Common/Constants";
import { hasPermissions } from "../helpers/PermissionHelper";
import { getGridFilterByCustomFilter } from "../helpers/SFGridUtils";
import { RAFSort } from "../helpers/types";
import { isEmptyArray, isNotEmptyArray, isNotNullAndUndefined, IsNullOrWhiteSpace } from "../helpers/utils";
import RAFForm from "../Inputs/RAFForm";
import RAFTextBoxWithIcon from "../Inputs/RAFTextBoxWithIcon";
import { RAFDataManager, RAFUrlAdaptor } from "../Inputs/RAFUrlAdaptor";
import { QueryAttributeJM } from "../models/Common/QueryAttributeJM";
import { RAFEntityBase } from "../models/Common/RAFEntityBase";
import { GroupByJM } from "../models/Common/RAFViewRow";
import { RAFEmptyStateProps } from "../Navigation/RAFEmptyState";
import { RAFDataResultContext } from "../Providers/RAFDataResultProvider";
import {
    RAFCustomFilter,
    RAFCustomOperator
} from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import loaderIcon from "../../assets/LoaderIconCircle.svg";
import { RAFDataListAdditionalProps } from "./RAFReportDataList";


export declare type RafGridFilterType = "Menu" | "Excel";
export declare type ExportType = "Pdf" | "Csv" | "Excel";

interface IProps {
    children?: ReactNode;
    currentSort?: RAFSort;
    currentFilter?: RAFCustomFilter;
    pageNumber?: number;
    pageSize?: number;
    data?: any;
    actions?: any;

    gridId?: string;
    entityType?: typeof RAFEntityBase;
    isRemote?: boolean;
    rowClick?: (rowData: Object) => void;
    editClick?: (rowData: Object) => void;
    customClick?: (rowData: Object) => void;
    customActionClick?: (rowData: Object) => void;
    allowSelection?: boolean;
    isRelatedSection?: boolean;
    filterType?: RafGridFilterType;
    actionButtons?: CommandModel[];
    deleteClicked?: (selectedRows: Object[]) => void;
    showToolbar?: boolean;
    allowEditing?: boolean;
    showEditColumn?: boolean;
    allowFiltering?: boolean;
    rowSelected?: (selectedRows: Object[]) => void;
    hasClientTerms?: boolean;
    gridTemplates?: GridColumnTemplates[];
    gridColumnWidth?: GridColumnWidth[];
    gridColumnHeader?: GridColumnHeader[];
    showEdit?: boolean;
    rowTemplate?: any;
    groupByColumns?: GroupByJM[];
    emptyStateProps?: RAFEmptyStateProps;
    disableFirstColumnTemplate?: boolean;
    sortBy?: RAFSort;
    actionButtonClicked?: (id: string, selectedRow: Object) => void;
    pageSizes?: number[];
    moduleName?: string;
    idField?: string;
    listCssClass?: string;

    showHeader?: boolean;
    hasOtherViews?: boolean;
    fields?: QueryAttributeJM[];
    templateHelpers?: any;
    checkPermissionEdit?: string[];
    checkPermissionSelection?: string[];
    frozenColumns?: number;
    url: string;
    additionalParams?: { key: string; value?: string | string[]; }[];
    removeSelectionOnRowClick?: boolean;
    searchFields?: string[];
    headerTitle?: string;
}

interface IState {
    data?: any;
}

class RAFListViewComponent extends React.Component<
    IProps & RAFDataListAdditionalProps,
    IState
> {
    context: any;
    private tooltip: TooltipComponent;
    public selectedRecords: Object[];

    private listViewComponent: ListViewComponent | null;

    //console.log('RAFGrid1 props=', props);
    //private data;
    private pageNumber;
    private pageSize;
    private currentSort;
    private currentFilter;

    private reachedMaxDataLimit = false;
    private inputSearchText = null;
    private skip = 0;
    private take: number = Constants.ListViewFetchCount;
    private filteredSkip = 0;
    private filteredTake: number = Constants.ListViewFetchCount;
    private customFilter: RAFCustomFilter;
    private customerData: RAFDataManager = new RAFDataManager({
        adaptor: new RAFUrlAdaptor({
            Skip: this.skip,
            Take: this.take,
            CustomFilter: null,
            additionalParams: null,
        }),
        crossDomain: true,
        //url: this.props.url,
        requestType: "POST",
        headers: [],
    });
    private query: Query = new Query(); //.from('Products').select('ProductID,ProductName').take(10);

    private allowFiltering = true;
    private showHeader = true;
    private isRemote = false;
    private allowSelection = true;
    private isRelatedSection = false;
    private allowEditing = false;
    private showEditColumn = false;
    private hasOtherViews = false;
    private gridTemplates?: GridColumnTemplates[];
    private emptyStateProps?: RAFEmptyStateProps;
    private showToolbar = true;
    private disableFirstColumnTemplate = false;
    private rowTemplate: any;
    private pageSettings: PageSettingsModel = {
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 25, 50, 100],
    };
    private sortingOptions: SortSettingsModel = { allowUnsort: true };
    private filterSettings: FilterSettingsModel = {
        showFilterBarStatus: false,
        mode: "Immediate",
        type: "FilterBar",
    };
    private filterOptions: FilterSettingsModel = { type: "Menu" };
    private groupSettings: GroupSettingsModel = {
        showDropArea: false,
        showGroupedColumn: false,
        showToggleButton: false,
        showUngroupButton: false,
    };
    private selectionsettingsModel: SelectionSettingsModel = {
        checkboxOnly: true,
        //persistSelection: true
    };
    private stringOperators: Object[] = [
        { value: "startswith", text: "Starts With" },
        { value: "endswith", text: "Ends With" },
        { value: "contains", text: "Contains" },
        { value: "equal", text: "Equal" },
        { value: "notequal", text: "Not Equal" },
    ];

    private getCommandModel = (): CommandModel[] => {
        let retVal: CommandModel[] = [];
        if (
            isNotNullAndUndefined(this.props.actionButtons) &&
            this.props.actionButtons.length > 0
        ) {
            retVal.push(...this.props.actionButtons);
        }
        return retVal;
    };

    constructor(props: IProps & RAFDataListAdditionalProps) {
        super(props);

        if (props.data) {
            //this.data = props.data.data;
            this.pageNumber = props.data.pageNumber;
            this.pageSize = props.data.pageSize;
            this.currentSort = props.data.currentSort;
            this.currentFilter = props.data.currentFilter;
            this.pageSettings.currentPage = this.pageNumber ?? 1;
            this.pageSettings.pageSize = this.pageSize ?? 10;
            if (this.currentSort && !IsNullOrWhiteSpace(this.currentSort.field)) {
                this.sortingOptions.columns = [
                    { field: this.currentSort.field, direction: this.currentSort.order },
                ];
            }

            if (this.currentFilter) {
                this.filterSettings.columns = getGridFilterByCustomFilter(
                    this.currentFilter
                );
            }

            this.state = {
                data: { ...props.data.data },
            };
        } else {
            this.state = {};
        }
        this.allowFiltering = props.allowFiltering ?? true;
        this.showHeader = props.showHeader ?? false;
        this.isRemote = props.isRemote ?? true;
        this.allowSelection = props.allowSelection ?? true;
        this.isRelatedSection = props.isRelatedSection ?? false;
        this.allowEditing = props.allowEditing ?? false;
        this.showEditColumn = props.showEditColumn ?? false;
        this.hasOtherViews = props.hasOtherViews ?? false;
        this.gridTemplates = props.gridTemplates ?? [];
        this.emptyStateProps = props.emptyStateProps ?? null;
        this.showToolbar = props.showToolbar ?? true;
        this.disableFirstColumnTemplate = props.disableFirstColumnTemplate ?? false;
        this.rowTemplate = props.rowTemplate ?? null;

        this.take = this.pageSize ?? Constants.ListViewFetchCount;
        this.filteredTake = this.pageSize ?? Constants.ListViewFetchCount;
        this.customFilter = props.currentFilter ?? null;
        this.currentSort = props.currentSort ?? null;
        this.customerData = new RAFDataManager({
            adaptor: new RAFUrlAdaptor({
                Skip: this.skip,
                Take: this.take,
                CustomFilter: this.customFilter,
                additionalParams: props.additionalParams ?? null,
                viewId: props.viewId ?? null,
                viewName: props.viewName ?? null,
                Sort: this.currentSort,
            }),
            crossDomain: true,
            url: this.props.url,
            requestType: "POST",
            headers: [],
        });
    }

    _isMounted = false;

    componentDidMount = () => {
        this._isMounted = true;
        //console.log('componentDidMount', this.listViewComponent);
        this.createSpinner();
        this.showSpinner();
        this.hideNoRecordDiv();
        if (isNotNullAndUndefined(this.listViewComponent)) {
            this.listViewComponent.dataSource = [];
            this.customerData
                //.executeQuery(filterQuery.range(start, end))
                .executeQuery(new Query())
                .then((event) => {
                    //start = end;
                    //end += 5;
                    this.hideGridSpinner();
                    let data = event as any;
                    if (
                        isNotNullAndUndefined(this.context) &&
                        isNotNullAndUndefined(this.context.updateData) &&
                        isNotNullAndUndefined(data)
                    )
                        this.context.updateData(data.result, data.actual.TotalCount);

                    if (isNotNullAndUndefined(data) && isNotNullAndUndefined(data.result) && data.result.length > 0) {
                        this.hideNoRecordDiv();
                    }
                    else {
                        this.showNoRecordDiv();
                    }

                    if (isNotNullAndUndefined(this.listViewComponent)) {
                        //console.log(this.listViewComponent);
                        if (
                            isNotNullAndUndefined(data)
                            && isNotNullAndUndefined(data.result)
                            && isNotNullAndUndefined(data.result as { [key: string]: Object; }[])
                            && (data.result as { [key: string]: Object; }[]).length > 0
                        ) {
                            this.listViewComponent.addItem(
                                data.result as { [key: string]: Object; }[]
                            );
                        }
                    }
                })
                .catch(/*e => { }*/);
        }
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    //const { fetchData, filtering, paging, sorting } = props.actions;
    //const { gridId, moduleName, idField, fields, children, templateHelpers } = props;

    today: Date = new Date(new Date().toDateString());
    weekStart: Date = new Date(
        new Date(
            new Date().setDate(new Date().getDate() - ((new Date().getDay() + 7) % 7))
        ).toDateString()
    );
    weekEnd: Date = new Date(
        new Date(
            new Date().setDate(
                new Date(
                    new Date().setDate(
                        new Date().getDate() - ((new Date().getDay() + 7) % 7)
                    )
                ).getDate() + 6
            )
        ).toDateString()
    );
    monthStart: Date = new Date(new Date(new Date().setDate(1)).toDateString());
    monthEnd: Date = this.today;
    lastStart: Date = new Date(
        new Date(
            new Date(new Date().setMonth(new Date().getMonth() - 1)).setDate(1)
        ).toDateString()
    );
    lastEnd: Date = this.today;
    yearStart: Date = new Date(
        new Date(new Date().setDate(new Date().getDate() - 365)).toDateString()
    );
    yearEnd: Date = this.today;

    presetDateModel: PresetsModel[] = [
        { label: "This Week", start: this.weekStart, end: this.weekEnd },
        { label: "This Month", start: this.monthStart, end: this.monthEnd },
        { label: "Last Month", start: this.lastStart, end: this.lastEnd },
        { label: "Last Year", start: this.yearStart, end: this.yearEnd },
    ];

    private hideGridSpinner = () => {
        setTimeout(() => {
            let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
            if (this.listViewComponent && this.listViewComponent.element) {
                const listParentDiv =
                    this.listViewComponent.element.closest("#divGridParent");
                if (listParentDiv) {
                    const customSpinner = listParentDiv.querySelector(
                        "#customSpinner"
                    ) as HTMLElement;
                    if (
                        isNotNullAndUndefined(customSpinner) &&
                        !IsNullOrWhiteSpace(customSpinner.innerHTML)
                    ) {
                        spinnerTarget = listParentDiv.querySelector(
                            "#customSpinner"
                        ) as HTMLElement;
                        customSpinner.classList.add("hidden");
                    }
                }
            }
            hideSpinner(spinnerTarget);
        }, 100);
    };

    private renderGrid = (fields?: QueryAttributeJM[]) => {
        return (
            <div
                className={
                    this.isRelatedSection
                        ? "e-bigger is-related-section"
                        : "e-bigger h-100"
                }
            >
                <ListViewComponent
                    fields={{ text: "ProcessName", tooltip: "ProcessName", id: "UID" }}
                    cssClass="e-list-template customScrollBar"
                    //height={100}
                    ref={(g) => (this.listViewComponent = g)}
                    query={this.query as any}
                    //enableVirtualization
                    {...(this.props.rowTemplate
                        ? { template: this.props.rowTemplate }
                        : {})}
                    actionBegin={this.actionBegin.bind(this)}
                    actionComplete={this.actionComplete.bind(this)}
                    select={this.onSelect.bind(this) as any}
                    className={
                        isNotNullAndUndefined(this.props.listCssClass)
                            ? this.props.listCssClass + " rafSFList hover-table-div h-100"
                            : "rafSFList hover-table-div h-100"
                    }
                    showHeader={this.showHeader}
                    headerTitle={this.props.headerTitle}
                >
                    <Inject services={[Virtualization]} />
                </ListViewComponent>
                <div id="divListViewNoData" className="hidden bg-white p-3">{this.getEmptyRecordMessage()}</div>
            </div>
        );
    };

    private getEmptyRecordMessage = () => {
        if (this.emptyStateProps) {
            return (
                <div className='empty-state-outer'>
                    <div className='empty-state-container no-record-div'>
                        {
                            (this.emptyStateProps.image || this.emptyStateProps.iconClass) &&
                            <div className={isNotNullAndUndefined(this.emptyStateProps.image) ? "empty-state-image" : "empty-state-icon"}>
                                {this.emptyStateProps.image ?
                                    <img src={this.emptyStateProps.image} alt="Icon Image" />
                                    :
                                    <span className={this.emptyStateProps.iconClass} />

                                }
                            </div>
                        }
                        {
                            this.emptyStateProps.title &&
                            <div className='empty-state-title'>
                                <span className='no-record-text1'>
                                    {this.emptyStateProps.title}
                                </span>
                            </div>
                        }
                        {
                            this.emptyStateProps.body &&
                            <div className='empty-state-content'>
                                <span>
                                    {this.emptyStateProps.body}
                                </span>
                            </div>
                        }
                    </div>
                </div>
            );
        } else {
            return (
                <div className="no-record-div" id="noRecordDiv11" style={{ textAlign: 'center' }}>
                    <img src={noRecordIcon} width="52px" className="my-3" />
                    <span className="no-record-text">No data available at the moment!</span>
                    <span className="no-record-text1"></span>
                </div>
            );
        }

    };

    private createSpinner = () => {
        let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
        if (this.listViewComponent && this.listViewComponent.element) {
            const listParentDiv =
                this.listViewComponent.element.closest("#divGridParent");
            if (listParentDiv) {
                const customSpinner = listParentDiv.querySelector(
                    "#customSpinner"
                ) as HTMLElement;
                if (
                    isNotNullAndUndefined(customSpinner) &&
                    !IsNullOrWhiteSpace(customSpinner.innerHTML)
                ) {
                    spinnerTarget = listParentDiv.querySelector(
                        "#customSpinner"
                    ) as HTMLElement;
                }
            }
        }

        createSpinner({
            target: spinnerTarget,
            width: "20px",
            template:
                '<div class="row g-0 wrapper-content loadingPanelGrid"> <div class="d-flex flex-column align-items-center" > <img src=' +
                loaderIcon +
                ' width="52" /> <span class="loadingText">Loading...</span> </div> </div >',
        });
    };

    onActionMenuClicked = (selectedMenu: MenuEventArgs, data) => {
        selectedMenu.event.stopPropagation();
        if (this.props.actionButtonClicked) {
            this.props.actionButtonClicked(selectedMenu.item.id, data);
        }
    };

    private actionBegin = () => {
        if (isNotNullAndUndefined(this.listViewComponent)) {
            let listElement: HTMLElement = this.listViewComponent.element;
            //console.log('actionComplete listElement', listElement);
            listElement.addEventListener("scroll", (e) => {
                if (
                    listElement.scrollTop + listElement.offsetHeight + 1 >=
                    listElement.scrollHeight
                ) {
                    //console.log('scroll', e);
                    this.setScrollDebounce(e);
                }
            });
        }
    };

    private actionComplete = (
        args
        //: ActionEventArgs
    ) => {
        this.hideGridSpinner();
    };

    public onSelect(
        e: SelectEventArgs
        //: SelectEventArgs
    ): void {
        if (
            this.props.removeSelectionOnRowClick === true &&
            this.listViewComponent
        ) {
            this.listViewComponent.selectItem(null);
        }
        if (
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-custombutton")
            ) &&
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-custombutton").innerHTML
            )
        ) {
            if (this.props.customClick) {
                this.props.customClick(e.data);
            }
        } else if (
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-custom-actionbutton")
            ) &&
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-custom-actionbutton")
                    .innerHTML
            )
        ) {
            if (this.props.customActionClick) {
                this.props.customActionClick(e.data);
            }
        } else if (
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-editbutton")
            ) &&
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".e-editbutton").innerHTML
            )
        ) {
            if (this.props.editClick) {
                this.props.editClick(e.data);
            }
        } else if (
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".noRowclick")
            ) &&
            isNotNullAndUndefined(
                (e.event.target as HTMLElement).closest(".noRowclick").innerHTML
            )
        ) {
            return;
        } else if (this.props.rowClick) {
            this.props.rowClick(e.data);
        }
    }

    private setScrollDebounce = debounce(() => {
        if (isNotNullAndUndefined(this.listViewComponent) && this.reachedMaxDataLimit !== true) {
            this.showSpinner();
            let filterQuery = isNotNullAndUndefined(this.listViewComponent.query)
                ? this.listViewComponent.query.clone()
                : new Query();
            const filteredText =
                isNotNullAndUndefined(this.listViewComponent["searchKeyModule"]) &&
                    isNotNullAndUndefined(
                        this.listViewComponent["searchKeyModule"]["element"]
                    )
                    ? this.listViewComponent["searchKeyModule"]["element"]["value"]
                    : null;

            if (isNotNullAndUndefined(this.inputSearchText) || (isNotNullAndUndefined(filteredText) && filteredText !== "")) {
                this.filteredSkip += this.filteredTake;
                //filterQuery = filterQuery.where(field.toString(), "contains", filteredText, true);
                if (isNotNullAndUndefined(this.customFilter)) {
                    this.customerData.adaptor = new RAFUrlAdaptor({
                        Skip: this.filteredSkip,
                        Take: this.filteredTake,
                        CustomFilter: this.customFilter,
                        additionalParams: this.props.additionalParams ?? null,
                        viewId: this.props.viewId ?? null,
                        viewName: this.props.viewName ?? null,
                        Sort: this.currentSort,
                    });
                } else {
                    if (
                        isNotEmptyArray(this.props.searchFields)
                    ) {
                        if (isNotNullAndUndefined(this.inputSearchText)) {
                            let customFilter: RAFCustomFilter = {};
                            customFilter.Condition = "and";
                            customFilter.Rules = [];

                            let searchFilter: RAFCustomFilter = {};
                            searchFilter.Condition = "or";
                            searchFilter.Rules = [];

                            this.props.searchFields.forEach((searchField) => {
                                let filter: RAFCustomFilter = {};
                                let filterVal: string[] = [];
                                filterVal.push(this.inputSearchText);
                                filter.Operator = RAFCustomOperator.Contains;
                                filter.Value = filterVal;
                                filter.Field = searchField;
                                searchFilter.Rules.push(filter);
                            });
                            customFilter.Rules.push(searchFilter);
                            this.skip += this.take;
                            this.customerData.adaptor = new RAFUrlAdaptor({
                                Skip: this.skip,
                                Take: this.take,
                                CustomFilter: customFilter,
                                additionalParams: this.props.additionalParams ?? null,
                                viewId: this.props.viewId ?? null,
                                viewName: this.props.viewName ?? null,
                                Sort: this.currentSort,
                            });
                        }
                    }
                }

            } else {
                this.skip += this.take;
                this.customerData.adaptor = new RAFUrlAdaptor({
                    Skip: this.skip,
                    Take: this.take,
                    CustomFilter: this.customFilter,
                    additionalParams: this.props.additionalParams ?? null,
                    viewId: this.props.viewId ?? null,
                    viewName: this.props.viewName ?? null,
                    Sort: this.currentSort,
                });
            }
            this.customerData
                //.executeQuery(filterQuery.range(start, end))
                .executeQuery(filterQuery)
                .then((event) => {
                    //start = end;
                    //end += 5;
                    this.hideGridSpinner();
                    let data = event as any;

                    if (isNotNullAndUndefined(data)) {
                        if (isEmptyArray(data.result)) {
                            this.reachedMaxDataLimit = true;
                        } else if (isNotEmptyArray(data.result) && data.result.length !== this.take) {
                            this.reachedMaxDataLimit = true;
                        } else {
                            this.reachedMaxDataLimit = false;
                        }
                    }

                    if (
                        isNotNullAndUndefined(this.context) &&
                        isNotNullAndUndefined(this.context.updateData) &&
                        isNotNullAndUndefined(data)
                    )
                        this.context.updateData(data.result, data.actual.TotalCount);

                    if (
                        isNotNullAndUndefined(event) &&
                        isNotNullAndUndefined(data.result)
                        && isNotNullAndUndefined(data.result as { [key: string]: Object; }[])
                        && (data.result as { [key: string]: Object; }[]).length > 0
                    ) {
                        this.listViewComponent.addItem(
                            data.result as { [key: string]: Object; }[]
                        );
                    }
                })
                .catch(/*e => { }*/);
        }
    }, 500);

    private showSpinner = () => {
        let spinnerTarget: HTMLElement = document.querySelector("#customSpinner");
        if (this.listViewComponent && this.listViewComponent.element) {
            const listParentDiv =
                this.listViewComponent.element.closest("#divGridParent");
            if (listParentDiv) {
                const customSpinner = listParentDiv.querySelector(
                    "#customSpinner"
                ) as HTMLElement;
                if (
                    isNotNullAndUndefined(customSpinner) &&
                    !IsNullOrWhiteSpace(customSpinner.innerHTML)
                ) {
                    spinnerTarget = listParentDiv.querySelector(
                        "#customSpinner"
                    ) as HTMLElement;
                    customSpinner.classList.remove('hidden');
                }
            }
        }
        showSpinner(spinnerTarget);
    };

    private showNoRecordDiv = () => {
        let divRAFSFList: HTMLElement = document.querySelector(".rafSFList");
        let divListViewNoData: HTMLElement = document.querySelector("#divListViewNoData");
        if (this.listViewComponent && this.listViewComponent.element) {
            const listParentDiv = this.listViewComponent.element.closest("#divGridParent");
            if (listParentDiv) {
                const divListViewNoData1 = listParentDiv.querySelector("#divListViewNoData") as HTMLElement;
                if (isNotNullAndUndefined(divListViewNoData1) && !IsNullOrWhiteSpace(divListViewNoData1.innerHTML)) {
                    divListViewNoData = listParentDiv.querySelector("#divListViewNoData") as HTMLElement;
                }

                const divRAFSFList1 = listParentDiv.querySelector(".rafSFList") as HTMLElement;
                if (isNotNullAndUndefined(divRAFSFList1) && !IsNullOrWhiteSpace(divRAFSFList1.innerHTML)) {
                    divRAFSFList = listParentDiv.querySelector(".rafSFList") as HTMLElement;
                }
            }
        }
        if (isNotNullAndUndefined(divListViewNoData))
            divListViewNoData.classList.remove('hidden');
        if (isNotNullAndUndefined(divRAFSFList))
            divRAFSFList.classList.add('hidden');
    };

    private hideNoRecordDiv = () => {
        let divRAFSFList: HTMLElement = document.querySelector(".rafSFList");
        let divListViewNoData: HTMLElement = document.querySelector("#divListViewNoData");
        if (this.listViewComponent && this.listViewComponent.element) {
            const listParentDiv = this.listViewComponent.element.closest("#divGridParent");
            if (listParentDiv) {
                const divListViewNoData1 = listParentDiv.querySelector("#divListViewNoData") as HTMLElement;
                if (isNotNullAndUndefined(divListViewNoData1) && !IsNullOrWhiteSpace(divListViewNoData1.innerHTML)) {
                    divListViewNoData = listParentDiv.querySelector("#divListViewNoData") as HTMLElement;
                }

                const divRAFSFList1 = listParentDiv.querySelector(".rafSFList") as HTMLElement;
                if (isNotNullAndUndefined(divRAFSFList1) && !IsNullOrWhiteSpace(divRAFSFList1.innerHTML)) {
                    divRAFSFList = listParentDiv.querySelector(".rafSFList") as HTMLElement;
                }
            }
        }
        divListViewNoData && divListViewNoData.classList.add('hidden');
        divRAFSFList && divRAFSFList.classList.remove('hidden');
    };

    private beforeRender = (args) => {
        let innerText = args.target.closest("td").innerText;
        let closestTd = args.target.closest("td");
        this.tooltip.content = innerText;
        this.tooltip.cssClass = "";
        if (IsNullOrWhiteSpace(innerText)) {
            this.tooltip.cssClass = "d-none";
        }
        if (
            isNotNullAndUndefined(closestTd) &&
            isNotNullAndUndefined(closestTd.querySelector(".removeTooltip"))
        ) {
            this.tooltip.cssClass = "d-none";
        }
    };

    onFiltering = (args) => {
        this.setFilteringDebounce(args);
    };

    setFilteringDebounce = debounce((searchText) => {
        this.reachedMaxDataLimit = false;
        this.inputSearchText = searchText;
        this.listViewComponent.dataSource = [];
        let customFilter: RAFCustomFilter = {};
        customFilter.Condition = "and";
        customFilter.Rules = [];
        if (
            isNotNullAndUndefined(this.props.currentFilter) &&
            isNotNullAndUndefined(this.props.currentFilter.Rules) &&
            this.props.currentFilter.Rules.length > 0
        ) {
            this.props.currentFilter.Rules.forEach((rules) => {
                customFilter.Rules.push(rules);
            });
        }

        let searchFilter: RAFCustomFilter = {};
        searchFilter.Condition = "or";
        searchFilter.Rules = [];

        if (
            isNotEmptyArray(this.props.searchFields)
        ) {
            if (isNotNullAndUndefined(searchText)) {
                this.showSpinner();

                this.props.searchFields.forEach((searchField) => {
                    let filter: RAFCustomFilter = {};
                    let filterVal: string[] = [];
                    filterVal.push(searchText);
                    filter.Operator = RAFCustomOperator.Contains;
                    filter.Value = filterVal;
                    filter.Field = searchField;
                    searchFilter.Rules.push(filter);
                });
                customFilter.Rules.push(searchFilter);
            }
        }

        this.hideNoRecordDiv();
        this.skip = 0;
        this.take = Constants.ListViewFetchCount;
        this.customerData.adaptor = new RAFUrlAdaptor({
            Skip: this.skip,
            Take: this.take,
            CustomFilter: customFilter,
            additionalParams: this.props.additionalParams ?? null,
            viewId: this.props.viewId ?? null,
            viewName: this.props.viewName ?? null,
            Sort: this.currentSort,
        });

        let filterQuery = new Query();

        this.customerData.executeQuery(filterQuery).then((event) => {
            this.hideGridSpinner();
            let data = event as any;
            if (isNotNullAndUndefined(data) && isNotNullAndUndefined(data.result) && data.result.length > 0) {
                this.hideNoRecordDiv();
            }
            else {
                this.showNoRecordDiv();
            }
            if (isNotNullAndUndefined(data) && isNotNullAndUndefined(data.result)
                && isNotNullAndUndefined(data.result as { [key: string]: Object; }[])
                && (data.result as { [key: string]: Object; }[]).length > 0
            ) {
                this.listViewComponent.addItem(
                    data.result as { [key: string]: Object; }[]
                );
            }
        });
    }, 500);

    render() {
        return (
            <RolePermissionsContext.Consumer>
                {({ permissionValue }) => {
                    if (
                        isNotNullAndUndefined(permissionValue) &&
                        isNotNullAndUndefined(this.props.checkPermissionSelection) &&
                        isNotNullAndUndefined(this.props.checkPermissionEdit)
                    ) {
                        let allowEditing = hasPermissions(
                            permissionValue,
                            this.props.checkPermissionEdit
                        );
                        let allowSelection = hasPermissions(
                            permissionValue,
                            this.props.checkPermissionSelection
                        );
                        if (this.allowEditing === true) {
                            this.allowEditing = allowEditing;
                        }
                        if (this.allowSelection === true) {
                            this.allowSelection = allowSelection;
                        }
                    }
                    return (
                        <RAFForm className="h-100">
                            <div className="h-100 d-flex flex-column">
                                {(isNotEmptyArray(this.props.searchFields) && this.props.allowFiltering === true) ? (
                                    <div className="e-filterbar mb-2">
                                        <RAFTextBoxWithIcon
                                            field="Serach"
                                            placeholder="Search by keyword"
                                            showLabel={false}
                                            iconCss="fa-solid fa-magnifying-glass"
                                            formGroupClassName="m-0"
                                            onKeyPressed={(e) => this.onFiltering(e)}
                                            showClearButton
                                        ></RAFTextBoxWithIcon>
                                    </div>
                                ) : null}
                                <div
                                    className="hover-table-div h-100 list-grid-outter overflow-auto customScrollBar"
                                    id="divGridParent"
                                >
                                    {/*{showToolbar && <ToolbarComponent id='toolbar-actions' cssClass="toolbarhidden" clicked={toolbarActionClickHandler.bind(this)}>
                </ToolbarComponent>
                }*/}
                                    <div id="customSpinner"></div>
                                    <TooltipComponent
                                        ref={(t) => (this.tooltip = t)}
                                        target=".e-rowcell"
                                        beforeRender={this.beforeRender.bind(this)}
                                        className="h-100"
                                    >
                                        {this.renderGrid(this.props.fields)}
                                    </TooltipComponent>
                                </div>
                            </div>
                        </RAFForm>
                    );
                }}
            </RolePermissionsContext.Consumer>
        );
    }
}
RAFListViewComponent.contextType = RAFDataResultContext;

export default RAFListViewComponent;
