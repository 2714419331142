import * as React from "react";
import { createContext, ReactNode } from "react";
import { ListServiceRequest } from "../../RAFComponents/models/Common/ListRequest";
import * as repositoryActions from "../../RAFComponents/store/actions/repositoryActions";
import { RAFDataResultContext } from "../Providers/RAFDataResultProvider";
import { ContentType } from "../../constants/Common/Constants";
import { RAFIdentifier, RAFSort } from "../helpers/types";
import {
    combineFilters,
    deepEqual,
    getSaveRequest,
    Guid,
    isArray,
    isNotNullAndUndefined,
    IsNullOrWhiteSpace,
    objectWithOnly,
    wrapChildrenWith,
} from "../helpers/utils";
import {
    RAFCustomFilter,
    RAFCustomOperator,
} from "../RAFViewPanels/RAFFilterColumn/RAFCustomFilter";
import { hideProgress, showProgress } from "../helpers/AppHelper";
import { getGridFilterByCustomFilter } from "../helpers/SFGridUtils";
import { DataManager } from "@syncfusion/ej2-data";
import ACLoadingPanel from "../../components/shared/ACLoadingPanel";

export interface IState {
    currentSort: RAFSort;
    currentFilter: RAFCustomFilter;
    pageNumber: number;
    pageSize: number;
    data: any;
    loading: boolean;
}

export const RAFReportDataListContext = createContext({
    data: null,
    actions: null,
});

export interface ListControllerProps {
    //basePath: string;
    currentSort: RAFSort;
    currentFilter: RAFCustomFilter;
    //data: RecordMap<RecordType>;
    //defaultTitle?: string;
    //displayedFilters: any;
    //error?: any;
    //exporter?: Exporter | false;
    filterValues: any;
    //hasCreate: boolean;
    //hideFilter: (filterName: string) => void;
    //ids: Identifier[];
    loading: boolean;
    loaded: boolean;
    //onSelect: (ids: Identifier[]) => void;
    //onToggleItem: (id: Identifier) => void;
    //onUnselectItems: () => void;
    pageNumber: number;
    pageSize: number;
    //resource: string;
    selectedIds: RAFIdentifier[];
    //setFilters: (filters: any, displayedFilters: any) => void;
    setPage: (page: number) => void;
    setPerPage: (page: number) => void;
    setSort: (sort: string, order?: string) => void;
    //showFilter: (filterName: string, defaultValue: any) => void;
    //total: number;

    dataResult: RAFDataResult;
}

export const RAFDataListContext = createContext<ListControllerProps>({
    //basePath: null,
    currentSort: null,
    currentFilter: null,
    //data: null,
    //defaultTitle: null,
    //displayedFilters: null,
    filterValues: null,
    //hasCreate: null,
    //hideFilter: null,
    //ids: null,
    loaded: null,
    loading: null,
    //onSelect: null,
    //onToggleItem: null,
    //onUnselectItems: null,
    pageNumber: null,
    pageSize: null,
    //resource: null,
    selectedIds: null,
    //setFilters: null,
    setPage: null,
    setPerPage: null,
    setSort: null,
    //showFilter: null,
    //total: null,

    dataResult: null,
});

interface ListProps {
    children: ReactNode;
    //rafDataSource: entityTypeOrUrl;
    url: string;
    saveUrl?: string;
    groupByUrl?: string;
    isRelated?: boolean;
    additionalFilter?: RAFCustomFilter;
    viewId?: string;
    viewName?: string;
    reportId?: string;
    sortBy?: RAFSort;
    additionalParams?: { key: string; value?: string | string[]; }[];
    requestType?: "GET" | "POST";
    loaderImage?: string;
    loaderTarget?: string;
    isAnonymous?: boolean;
}

export class RAFDataResult {
    result?: Object[];
    count?: number;
}


export interface RAFDataListAdditionalProps {
    goToPage?: (pageNumber: number) => void;
    filtering?: (filter: RAFCustomFilter) => Promise<RAFDataResult>;
    paging?: (pageNumber: number, pageSize: number) => Promise<RAFDataResult>;
    sorting?: (sort: RAFSort) => Promise<RAFDataResult>;
    fetchData?: (
        pageNumber: number,
        pageSize: number,
        sort: RAFSort,
        filter: RAFCustomFilter
    ) => Promise<RAFDataResult>;
    groupBy?: (
        pageNumber: number,
        pageSize: number,
        sort: RAFSort,
        filter: RAFCustomFilter
    ) => Promise<RAFDataResult>;
    getDataForExport?: () => Promise<RAFDataResult>;
    dataSource?: RAFDataResult;
    saveData?: (rowData: Object, idField: string) => Promise<Object>;
    viewId?: string;
    viewName?: string;
}

class RAFReportDataList extends React.Component<ListProps, IState> {
    _isMounted = false;
    context: any;
    private rafSFGridService: RAFSFGridService = new RAFSFGridService();
    private objDivId = isNotNullAndUndefined(this.props.loaderTarget)
        ? this.props.loaderTarget
        : `divRAFReportDataList_${Guid.newGuid()}`;

    private _allDataResult: RAFDataResult;
    get dataResult(): RAFDataResult {
        return this._allDataResult;
    }
    set dataResult(value: RAFDataResult) {
        this._allDataResult = value;
    }

    constructor(props: ListProps) {
        super(props);

        let currentSort: RAFSort = { field: "", order: "" };
        if (
            isNotNullAndUndefined(props) &&
            isNotNullAndUndefined(props.sortBy) &&
            !IsNullOrWhiteSpace(props.sortBy.field)
        ) {
            currentSort = props.sortBy;
        }
        this.state = {
            currentSort: currentSort,
            currentFilter: {},
            pageNumber: 1,
            pageSize: 10,
            //data: this.fetchData(1, 10, currentSort, null),
            data: null,
            loading: true
        };
    }

    componentDidMount = () => {
        this._isMounted = true;
        this.fetchData(1, 10, this.state.currentSort, null);
    };

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const { children, ...rest } = this.props;
        const { loading } = this.state;
        let children1 = wrapChildrenWith(this.props.children, {
            viewId: this.props.viewId,
            viewName: this.props.viewName,
            data: this.state,
            actions: objectWithOnly(this, [
                "fetchData",
                "filtering",
                "paging",
                "sorting",
                "goToPage",
                "groupBy",
                "getDataForExport",
                "saveData",
            ]),
            ...rest,
        });

        return (
            <div className="h-100" id={this.objDivId}>
                <RAFReportDataListContext.Provider
                    value={{
                        data: this.state,
                        actions: objectWithOnly(this, [
                            "fetchData",
                            "filtering",
                            "paging",
                            "sorting",
                            "goToPage",
                            "groupBy",
                            "getDataForExport",
                            "saveData",
                        ]),
                    }}
                >
                    {loading === true ?
                        <div className="container-fluid px-0">
                            {/* <ACLoadingPanel loadingText="Preparing Data..." /> */}
                        </div>
                        : children1}
                </RAFReportDataListContext.Provider>
            </div>
        );
    }

    fetchData = (
        pageNumber: number,
        pageSize: number,
        sort?: RAFSort,
        filter?: RAFCustomFilter
    ) => {
        //console.log("1=fetchData", this.objDivId);

        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );
            //return
            this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    pageNumber,
                    pageSize,
                    sort,
                    filter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);

                    if (this._isMounted) {
                        this.setState(
                            {
                                pageNumber,
                                pageSize,
                                currentSort: sort,
                                currentFilter: filter,
                                data: gridData,
                                loading: false
                            },
                            () => {
                                this.context &&
                                    this.context.updateData &&
                                    this.context.updateData(gridData.result, gridData.count);
                            }
                        );
                    }
                });
        }
    };

    filtering = (filter?: RAFCustomFilter) => {
        //console.log('1=filter', filter)
        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        const pageNumber = 1;
        const { pageSize, currentSort } = this.state;

        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    pageNumber,
                    pageSize,
                    currentSort,
                    filter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    if (this._isMounted) {
                        this.setState({
                            currentFilter: filter,
                            pageNumber,
                            data: gridData,
                        });
                    }
                });
        }
    };

    paging = (pageNumber: number, pageSize: number) => {
        //console.log('1=paging', pageNumber, pageSize);

        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        const { currentSort, currentFilter } = this.state;

        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    pageNumber,
                    pageSize,
                    currentSort,
                    currentFilter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    //console.log('rafdatalist gridData', gridData);
                    if (this._isMounted) {
                        this.setState({ pageNumber, pageSize, data: gridData });
                    }
                });
        }
    };

    sorting = (sort?: RAFSort) => {
        //console.log('1=sorting', sort)

        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        const { pageNumber, pageSize, currentFilter } = this.state;

        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    pageNumber,
                    pageSize,
                    sort,
                    currentFilter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    if (this._isMounted) {
                        this.setState({ currentSort: sort, data: gridData });
                    }
                });
        }
    };

    goToPage = (pageNumber: number) => {
        //console.log('1=gotoPage', pageNumber);
        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        const { currentSort, currentFilter, pageSize } = this.state;

        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    pageNumber,
                    pageSize,
                    currentSort,
                    currentFilter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    if (this._isMounted) {
                        this.setState({ pageNumber, data: gridData });
                    }
                });
        }
    };

    groupBy = (
        pageNumber: number,
        pageSize: number,
        sort?: RAFSort,
        filter?: RAFCustomFilter
    ) => {
        //console.log('1=groupBy', pageNumber)

        const { url, groupByUrl, viewId, viewName, reportId, loaderImage } = this.props;

        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            this.rafSFGridService
                .getData(
                    groupByUrl,
                    viewId,
                    pageNumber,
                    pageSize,
                    sort,
                    filter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    if (this._isMounted) {
                        this.setState({
                            pageNumber,
                            pageSize,
                            currentSort: sort,
                            currentFilter: filter,
                            data: gridData,
                        });
                    }
                });
        }
    };

    getDataForExport = (): Promise<RAFDataResult> => {
        //console.log('1=getDataForExport')

        const { url, viewId, viewName, reportId, loaderImage } = this.props;
        const { currentSort, currentFilter } = this.state;
        if (isNotNullAndUndefined(url)) {
            let progressDiv = showProgress(
                "#" + this.objDivId,
                false,
                null,
                isNotNullAndUndefined(loaderImage) ? loaderImage : null
            );

            return this.rafSFGridService
                .getData(
                    url,
                    viewId,
                    1,
                    0,
                    currentSort,
                    currentFilter,
                    this.props.additionalFilter,
                    viewName,
                    reportId,
                    this.props.additionalParams,
                    this.props.requestType,
                    this.props.isAnonymous === true ? true : false
                )
                .then((gridData) => {
                    hideProgress(progressDiv);
                    return { ...gridData };
                });
        }
    };

    saveData = (rowData: Object, idField: string): Promise<Object> => {
        //console.log('1=gotoPage', pageNumber);
        const { saveUrl, loaderImage } = this.props;

        return new Promise<Object>((resolve) => {
            if (isNotNullAndUndefined(saveUrl)) {
                let progressDiv = showProgress(
                    "#" + this.objDivId,
                    false,
                    null,
                    isNotNullAndUndefined(loaderImage) ? loaderImage : null
                );
                repositoryActions
                    .postDataAndGetResponse(
                        saveUrl,
                        getSaveRequest(rowData, rowData[idField]),
                        null,
                        ContentType.applicationJson
                    )
                    .then((response) => {
                        hideProgress(progressDiv);
                        if (
                            isNotNullAndUndefined(response) &&
                            isNotNullAndUndefined(response.data)
                        ) {
                            if (
                                response.data.EntityId !== null &&
                                response.data.EntityId !== undefined &&
                                response.data.EntityId !== ""
                            ) {
                                rowData[idField] = response.data.EntityId;
                                resolve(rowData);
                            } else if (
                                response.data.Error !== null &&
                                response.data.Error !== undefined &&
                                response.data.Error !== ""
                            ) {
                                //console.log("Error on save", response.data.Error);
                            }
                        }
                    })
                    .catch((error) => error);
            } else {
                resolve(rowData);
            }
        });
    };

    setData = (
        pageNumber: number,
        pageSize: number,
        sort?: RAFSort,
        filter?: RAFCustomFilter,
        gridData?: RAFDataResult
    ) => {
        //console.log('1=fetchData', pageNumber)
        if (this._isMounted) {
            this.setState({
                pageNumber,
                pageSize,
                currentSort: sort,
                currentFilter: filter,
                data: gridData,
            });
        }
    };

    componentDidUpdate(prevProps: ListProps) {
        if (
            !deepEqual(prevProps.additionalFilter, this.props.additionalFilter) ||
            prevProps.viewId !== this.props.viewId ||
            prevProps.reportId !== this.props.reportId
        ) {
            if (
                isNotNullAndUndefined(this.props.sortBy) &&
                !IsNullOrWhiteSpace(this.props.sortBy.field)
            ) {
            }
            //this.fetchData(1, 10, currentSort, null)
        }
    }
}

export class RAFSFGridService {
    //public getData(url: string, pageNumber?: number, pageSize?: number, sort?: Sort[], objCustomFilter?: RAFCustomFilter): Promise<RAFDataResult> {
    public getData(
        url: string,
        viewId?: string,
        pageNumber?: number,
        pageSize?: number,
        sort?: RAFSort,
        objCustomFilter?: RAFCustomFilter,
        additionalFilter?: RAFCustomFilter,
        viewName?: string,
        reportId?: string,
        additionalParams?: { key: string; value?: string | string[]; }[],
        requestType?: "GET" | "POST",
        isAnonymous?: boolean
    ): Promise<RAFDataResult> {
        //url = url + (!IsNullOrWhiteSpace(viewId) ? ("/" + viewId) : "");

        //const listRequest: ListRequest = new ListRequest();
        const listRequest: ListServiceRequest = new ListServiceRequest();
        listRequest.ViewUID = viewId;
        listRequest.ViewName = viewName;
        listRequest.Take = isNotNullAndUndefined(pageSize) ? pageSize : 10;
        listRequest.Skip =
            ((isNotNullAndUndefined(pageNumber) ? pageNumber : 1) - 1) *
            (isNotNullAndUndefined(pageSize) ? pageSize : 10);
        //listRequest.CustomFilter = objCustomFilter;
        if (
            isNotNullAndUndefined(objCustomFilter) &&
            isNotNullAndUndefined(additionalFilter)
        ) {
            listRequest.CustomFilter = combineFilters(
                objCustomFilter,
                additionalFilter
            );
        } else if (isNotNullAndUndefined(additionalFilter)) {
            listRequest.CustomFilter = additionalFilter;
        } else if (isNotNullAndUndefined(objCustomFilter)) {
            listRequest.CustomFilter = objCustomFilter;
        }

        let sortQuery: string[] = [];

        //if ((sort || []).length) {
        //    sortQuery = (sort).map((obj: Sort) => {
        //        return obj.order === 'descending' ? `${obj.field} desc` : obj.field;
        //    }).reverse();
        //}
        if (
            isNotNullAndUndefined(sort) &&
            isNotNullAndUndefined(sort.field) &&
            !IsNullOrWhiteSpace(sort.field)
        ) {
            sortQuery.push(
                sort.order === "descending" ? `${sort.field} desc` : sort.field
            );
        }
        listRequest.Sort = sortQuery;
        if (!IsNullOrWhiteSpace(reportId)) {
            listRequest["ReportUID"] = reportId;
        }

        if (
            isNotNullAndUndefined(additionalParams) &&
            additionalParams.length > 0
        ) {
            additionalParams.forEach((objProp) => {
                listRequest[objProp.key] = objProp.value;
            });
        }
        let relatedFilter: RAFCustomFilter = {};
        relatedFilter.Condition = "and";
        relatedFilter.Rules = [];

        if (
            isNotNullAndUndefined(additionalParams) &&
            additionalParams.length > 0
        ) {
            additionalParams.forEach((objProp) => {
                let filter: RAFCustomFilter = {};
                let filterVal: string[] = [];
                if (typeof objProp.value === "object") {
                    filterVal = objProp.value as string[];
                } else {
                    filterVal.push(objProp.value);
                }
                filter.Operator = RAFCustomOperator.Equal;
                filter.Value = filterVal;
                filter.Field = objProp.key;
                relatedFilter.Rules.push(filter);
            });
        }
        // let dataManagerRequest = new DataManager();
        // dataManagerRequest["where"] = getGridFilterByCustomFilter(relatedFilter);
        // dataManagerRequest["requiresCounts"] = true;
        if (requestType === "GET") {
            if (isAnonymous === true) {
                return repositoryActions
                    .getDataAnonymous(url, listRequest, null, ContentType.applicationJson)
                    .then((response) => {
                        let gridData = { ...response.data };
                        if (
                            isNotNullAndUndefined(gridData) &&
                            isNotNullAndUndefined(gridData.ResultTable)
                        ) {
                            return {
                                count: gridData.TotalRows,
                                result: gridData.ResultTable,
                            };
                        } else {
                            if (
                                isNotNullAndUndefined(gridData) &&
                                isNotNullAndUndefined(gridData.Entities)
                            ) {
                                return {
                                    count: gridData.TotalCount,
                                    result: gridData.Entities,
                                };
                            } else {
                                if (isArray(response.data)) {
                                    return response.data;
                                    // return {
                                    //   count: gridData.length,
                                    //   result: gridData,
                                    // };
                                }
                                return {
                                    count: undefined,
                                    result: undefined,
                                };
                            }
                        }
                    })
                    .catch((error) => error);
            } else {
                return repositoryActions
                    .getData(url, listRequest, null, ContentType.applicationJson)
                    .then((response) => {
                        let gridData = { ...response.data };
                        if (
                            isNotNullAndUndefined(gridData) &&
                            isNotNullAndUndefined(gridData.ResultTable)
                        ) {
                            return {
                                count: gridData.TotalRows,
                                result: gridData.ResultTable,
                            };
                        } else {
                            if (
                                isNotNullAndUndefined(gridData) &&
                                isNotNullAndUndefined(gridData.Entities)
                            ) {
                                return {
                                    count: gridData.TotalCount,
                                    result: gridData.Entities,
                                };
                            } else {
                                if (isArray(response.data)) {
                                    return response.data;
                                    // return {
                                    //   count: gridData.length,
                                    //   result: gridData,
                                    // };
                                }
                                return {
                                    count: undefined,
                                    result: undefined,
                                };
                            }
                        }
                    })
                    .catch((error) => error);
            }
        } else {
            if (isAnonymous === true) {
                return repositoryActions
                    .postDataAnonymous(
                        url,
                        listRequest,
                        null,
                        ContentType.applicationJson
                    )
                    .then((response) => {
                        let gridData = { ...response.data };
                        if (
                            isNotNullAndUndefined(gridData) &&
                            isNotNullAndUndefined(gridData.ResultTable)
                        ) {
                            return {
                                count: gridData.TotalRows,
                                result: gridData.ResultTable,
                            };
                        } else {
                            if (
                                isNotNullAndUndefined(gridData) &&
                                isNotNullAndUndefined(gridData.Entities)
                            ) {
                                return {
                                    count: gridData.TotalCount,
                                    result: gridData.Entities,
                                };
                            } else {
                                if (isArray(response.data)) {
                                    return response.data;
                                    // return {
                                    //   count: gridData.length,
                                    //   result: gridData,
                                    // };
                                }
                                return {
                                    count: undefined,
                                    result: undefined,
                                };
                            }
                        }
                    })
                    .catch((error) => error);
            } else {
                return repositoryActions
                    .postDataAndGetResponse(
                        url,
                        listRequest,
                        //dataManagerRequest,
                        null,
                        ContentType.applicationJson
                    )
                    .then((response) => {
                        let gridData = { ...response.data };
                        if (
                            isNotNullAndUndefined(gridData) &&
                            isNotNullAndUndefined(gridData.ResultTable)
                        ) {
                            return {
                                count: gridData.TotalRows,
                                result: gridData.ResultTable,
                            };
                        } else {
                            if (
                                isNotNullAndUndefined(gridData) &&
                                isNotNullAndUndefined(gridData.Entities)
                            ) {
                                return {
                                    count: gridData.TotalCount,
                                    result: gridData.Entities,
                                };
                            } else {
                                if (isArray(response.data)) {
                                    return response.data;
                                    // return {
                                    //   count: gridData.length,
                                    //   result: gridData,
                                    // };
                                }
                                return {
                                    count: undefined,
                                    result: undefined,
                                };
                            }
                        }
                    })
                    .catch((error) => error);
            }
        }
    }
}

//RAFDataList.propTypes = {
//    // the props you can change
//    // @ts-ignore-line
//    //actions: PropTypes.oneOfType([PropTypes.bool, PropTypes.element]),
//    //aside: PropTypes.element,
//    // @ts-ignore-line
//    //bulkActionButtons: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
//    children: PropTypes.node,
//    //classes: PropTypes.object,
//    //className: PropTypes.string,
//    //filter: PropTypes.object,
//    //filterDefaultValues: PropTypes.object,
//    //filters: PropTypes.element,
//    // @ts-ignore-line
//    //pagination: PropTypes.oneOfType([PropTypes.element, PropTypes.bool]),
//    //perPage: PropTypes.number.isRequired,
//    //@ts-ignore-line
//    //sort: PropTypes.shape({
//    //    field: PropTypes.string,
//    //    order: PropTypes.string,
//    //}),
//    //title: TitlePropType,
//    // the props managed by react-admin
//    //authProvider: PropTypes.func,
//    //hasCreate: PropTypes.bool,//.isRequired,
//    //hasEdit: PropTypes.bool,//.isRequired,
//    //hasList: PropTypes.bool,//.isRequired,
//    //hasShow: PropTypes.bool,//.isRequired,
//    //location: PropTypes.any,
//    //match: PropTypes.any,
//    //path: PropTypes.string,
//    //resource: PropTypes.string,//.isRequired,

//    rafDataSource: PropTypes.oneOfType([PropTypes.objectOf(PropTypes.shape(RAFEntityBase)), PropTypes.string]).isRequired,

//    //url: PropTypes.string.isRequired,
//};

//RAFDataList.defaultProps = {
//filter: {},
//perPage: 10,
//};

RAFReportDataList.contextType = RAFDataResultContext;

export default RAFReportDataList;
